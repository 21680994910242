import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0205dc04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Map = _resolveComponent("Map")!
  const _component_SearchPC = _resolveComponent("SearchPC")!
  const _component_SearchSP = _resolveComponent("SearchSP")!
  const _component_Genre = _resolveComponent("Genre")!
  const _component_Location = _resolveComponent("Location")!
  const _component_DetailPC = _resolveComponent("DetailPC")!
  const _component_DetailSP = _resolveComponent("DetailSP")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.myLocation)
      ? (_openBlock(), _createBlock(_component_Map, {
          key: 0,
          class: "map"
        }))
      : _createCommentVNode("", true),
    (_ctx.isPC && _ctx.places)
      ? (_openBlock(), _createBlock(_component_SearchPC, {
          key: 1,
          class: "search-pc"
        }))
      : _createCommentVNode("", true),
    (_ctx.isSP && _ctx.places)
      ? (_openBlock(), _createBlock(_component_SearchSP, {
          key: 2,
          class: "search-sp"
        }))
      : _createCommentVNode("", true),
    (_ctx.places)
      ? (_openBlock(), _createBlock(_component_Genre, {
          key: 3,
          class: "genre"
        }))
      : _createCommentVNode("", true),
    (_ctx.myLocation)
      ? (_openBlock(), _createBlock(_component_Location, {
          key: 4,
          class: "location"
        }))
      : _createCommentVNode("", true),
    (_ctx.isPC)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 5,
          name: "slide"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedPlace)
              ? (_openBlock(), _createBlock(_component_DetailPC, {
                  key: 0,
                  class: "detail-pc"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isSP)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 6,
          name: "slide"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedPlace)
              ? (_openBlock(), _createBlock(_component_DetailSP, {
                  key: 0,
                  class: "detail-sp"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}