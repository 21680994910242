import { mapState, mapGetters } from 'vuex'
import Chart from '@/components/Chart.vue'
import genreHelper from '@/lib/GenreHelper.js'
import statusHelper from '@/lib/StatusHelper.js'

export default {
  components: {
    Chart,
  },
  computed: {
    ...mapState(['selectedPlace', 'trends']),
    ...mapGetters(['lastUpdateDateTime']),
  },
  methods: {
    genreClass: genreHelper.genreClass,
    genreLabel: genreHelper.genreLabel,
    statusClass: statusHelper.statusClass,
    statusDetailLabel: statusHelper.statusDetailLabel,
    statusDetailHeader: statusHelper.statusDetailHeader,
  }
}
