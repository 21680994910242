<template>
  <div class="news">
    <h2>NEWS</h2>
    <ul>
      <li>
        <h3 id="news-kamakura">2022.05.25</h3>
        <p><a href="https://www.konzatsu-kamakura.jp/" target="_blank">鎌倉の観光スポット近辺の曜日・時間帯別混雑傾向がわかる「鎌倉観光混雑マップ」サイトがオープンしました！</a></p>
        <div class="banners">
          <a href="https://www.konzatsu-kamakura.jp/" target="_blank"><img class="kamakura" src="@/assets/images/banner-kamakura.png"></a>
        </div>
      </li>
      <li>
        <h3>2021.04.15</h3>
        <p><a href="https://www.unerry.co.jp/news/covid19-map-for-drive/" target="_blank">ドライブ中にご利用できる、Echo Auto対応Alexaスキル「お買物混雑マップ for Drive」を公開しました（プレスリリースへ）</a></p>
      </li>
      <li>
        <h3>2021.01.12</h3>
        <p>「百貨店・モール」の推定混雑状況を追加。デパート、ショッピングセンター、アウトレットなどへお出かけの際の参考にもご利用ください。</p>
      </li>
      <li>
        <h3>2020.07.28</h3>
        <p><a href="https://www.unerry.co.jp/news/2020/07/customized-map" target="_blank">掲載店舗数が約2.8万店舗から、4.8万店舗に拡充しました（プレスリリースへ）</a></p>
      </li>
      <li>
        <h3 id="news-app">2020.06.19</h3>
        <p>姉妹版サービスとして、Androidアプリ「おでかけ混雑マップ」が登場！無料でご利用いただけます。</p>
        <div class="banners">
          <a href="https://play.google.com/store/apps/details?id=com.nttdata.odekonmap.apps" target="_blank"><img class="googleplay" src="@/assets/images/banner-googleplay.png"></a>
          <a href="https://apps.apple.com/jp/app/%E3%81%8A%E3%81%A7%E3%81%8B%E3%81%91%E6%B7%B7%E9%9B%91%E3%83%9E%E3%83%83%E3%83%97/id1525156653?mt=8" target="_blank"><img class="appstore" src="@/assets/images/banner-appstore.png"></a>
        </div>
      </li>
      <li>
        <h3>2020.06.01</h3>
        <p>混雑状況表示が7日間から8日間に増えました</p>
      </li>
      <li>
        <h3>2020.05.07</h3>
        <p><a href="https://www.unerry.co.jp/news/2020/05/covid19-map/" target="_blank">「お買物混雑マップ」を公開しました（プレスリリースへ）</a></p>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.news {
  margin: 27px auto 0;
  max-width: 1020px;
  padding: 0 20px 70px;

  h2 {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    background-color: #141431;
    color: #fff;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #EEEEEE;

      h3, p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        line-height: 26px;
        color: #000;
      }
      h3 {
        font-weight: bold;
      }
      p {
        color: #000;
      }
      a {
        color: #1D84C4;
        text-decoration: none;
      }

      .banners {
        margin-top: 13px;
        padding-bottom: 3px;

        .kamakura {
          width: 280px;
          height: 67px;
        }

        .googleplay {
          margin-right: 10px;
          width: 166px;
          height: 50px;
        }

        .appstore {
          margin-top: 3px;
          width: 135px;
          height: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .news {
    margin-top: 15px;
    padding-bottom: 50px;

    ul {
      .banners {
        .kamakura {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
