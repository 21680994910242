
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';
import VueApexCharts from 'vue3-apexcharts';
import '@/assets/styles/apexcharts.override.scss';

export default defineComponent({
  name: 'Chart',
  components: {
    VueApexCharts
  },
  props: {
    height: Number
  },
  computed: {
    ...mapState(['trends']),
    ...mapGetters(['isPC']),
    chartSeries() {
      if (!this.trends) {
        return null;
      }

      let grouped: any = {};

      for (let trend of this.trends) {
        grouped[trend.date] = grouped[trend.date] || [];
        grouped[trend.date].push(trend);
      }

      for (let key in grouped) {
        let trends = grouped[key];
        while (trends.length < 15) {
          trends.push({ hour: trends.length + 8, status: '不明' });
        }
      }

      return Object.keys(grouped)
        .sort()
        .reverse()
        .map((date) => {
          let trends = grouped[date];
          let d = new Date(date);
          var dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][
            d.getDay()
          ];

          return {
            name: d.getMonth() + 1 + '/' + d.getDate() + ' (' + dayOfWeek + ')',
            data: trends.map((trend: any) => {
              return {
                x: trend.hour + '時',
                y: { 不明: 0, 閑散: 1, 通常: 2, 混雑: 3 }[
                  trend.status as string
                ]
              };
            })
          };
        });
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false
          }
        },
        tooltip: {
          style: {
            fontSize: '11px'
          },
          y: {
            formatter: (t: any) => {
              return [
                '不明、データ無し',
                'いつもより空いている',
                '通常程度',
                '通常より混雑'
              ][t];
            },
            title: {
              formatter: (t: any, c: any) => {
                let xLabel = c.w.globals.labels[c.dataPointIndex];
                return t + ' ' + xLabel;
              }
            }
          }
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                { from: 3, to: 3, name: '…通常より混雑', color: '#D61A1A' },
                { from: 2, to: 2, name: '…通常程度', color: '#9DB41D' },
                {
                  from: 1,
                  to: 1,
                  name: '…いつもより空いている',
                  color: '#4AB2F2'
                },
                { from: 0, to: 0, name: '…不明', color: '#DDDDDD' }
              ]
            },
            enableShades: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          position: 'top',
          labels: {
            style: {
              fontSize: this.isPC ? '10px' : '7px'
            },
            offsetY: this.isPC ? -2 : -3
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: this.isPC ? '10px' : '7px'
            },
            offsetX: this.isPC ? 0 : 3
          }
        },
        legend: {
          position: 'bottom',
          fontSize: '12px',
          fontFamily:
            '"游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif',
          fontWeight: 'bold'
        }
      };
    }
  }
});
