<template>
  <div class="modal" @click.prevent="$emit('close')" @scroll.stop>
    <div class="modal-body" @click.stop>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100000;
  overflow-y: scroll;

  &-body {
    margin: 80px 12vw 120px;
    background-color: #fff;
    border-radius: 10px;
  }
}
</style>
