
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Content',
  computed: {
    ...mapGetters(['isWV']),
  },
})
