
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NoticeLine',
  data: function () {
    return {
      closed: false,
    }
  },
})
