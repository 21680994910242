export default {
  statusClass(status) {
    return {
      '不明': 'is-status-0',
      '閑散': 'is-status-1',
      '通常': 'is-status-2',
      '混雑': 'is-status-3',
    }[status]
  },
  statusSearchLabel(status) {
    return {
      '不明': 'ー',
      '閑散': '閑散',
      '通常': '通常',
      '混雑': '混雑',
    }[status]
  },
  statusDetailLabel(status, is_nodata) {
    if (is_nodata) {
      return '混雑状況が表示できません'
    }

    return {
      '不明': '混雑解析に時間がかかっています',
      '閑散': 'いつもより空いている',
      '通常': '通常程度の混み具合',
      '混雑': '通常より混雑',
    }[status]
  },
  statusDetailHeader(status, is_nodata) {
    if (is_nodata) {
      return 'データ不足'
    }

    return {
      '不明': '不明',
      '閑散': '直近の状況',
      '通常': '直近の状況',
      '混雑': '直近の状況',
    }[status]
  },
}
