import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.chartSeries)
      ? (_openBlock(), _createBlock(_component_VueApexCharts, {
          key: 0,
          type: "heatmap",
          height: _ctx.height,
          options: _ctx.chartOptions,
          series: _ctx.chartSeries
        }, null, 8, ["height", "options", "series"]))
      : _createCommentVNode("", true)
  ]))
}