import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attention = _resolveComponent("Attention")!
  const _component_Apologize = _resolveComponent("Apologize")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Content = _resolveComponent("Content")!
  const _component_NoticeLine = _resolveComponent("NoticeLine")!
  const _component_HelpLine = _resolveComponent("HelpLine")!
  const _component_Description = _resolveComponent("Description")!
  const _component_News = _resolveComponent("News")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Attention),
    _createVNode(_component_Apologize),
    _createElementVNode("div", {
      class: _normalizeClass({'is-drawr-opend': _ctx.isSP && _ctx.selectedPlace, 'is-wv': _ctx.isWV})
    }, [
      (!_ctx.isWV)
        ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("main", null, [
        _createVNode(_component_Content),
        false
          ? (_openBlock(), _createBlock(_component_NoticeLine, { key: 0 }))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createBlock(_component_HelpLine, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(_component_Description),
        (!_ctx.isWV)
          ? (_openBlock(), _createBlock(_component_News, { key: 2 }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Footer)
    ], 2)
  ]))
}