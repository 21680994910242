
import "normalize.css"
import { defineComponent } from "vue"
import store from "./store"

export default defineComponent({
  name: "App",
  methods: {
    handleResize: function() {
      store.commit('windowWidth', window.innerWidth)
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount: function () {
    window.removeEventListener('resize', this.handleResize)
  },
})
