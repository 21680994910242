
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
import Content from '@/components/Terms/Content.vue';

export default defineComponent({
  name: 'Footer',
  components: {
    Modal,
    Content
  },
  computed: {
    ...mapGetters(['isPC', 'isWV']),
    ...mapState(['selectedPlace'])
  },
  data: function () {
    return {
      termsShown: false
    };
  },
  methods: {
    showTerms() {
      this.termsShown = true;
      window.PageView({
        page_title: '利用規約(PC)',
        page_path: '/terms',
        page_location: location.origin + '/terms'
      });
    },
    closeTerms() {
      this.termsShown = false;

      let title = this.selectedPlace ? this.selectedPlace.name : document.title;
      let current = this.$router.currentRoute.value;
      window.PageView({
        page_title: title,
        page_path: current.fullPath,
        page_location: location.origin + current.fullPath
      });
    }
  }
});
