import './lib/CookieConsent'
import VueSmoothScroll from 'v-smooth-scroll'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)

app.mount('#app')

store.dispatch('loadMyLocation')
store.dispatch('loadAllPlaces')
