
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

export default defineComponent({
  name: 'Header',
  computed: {
    ...mapGetters(['isPC', 'isSP']),
    ...mapState(['selectedPlace'])
  },
  methods: {
    scrollToNews() {
      // モバイルの場合詳細表示だとNews隠しているのでホームに戻す
      if (this.isSP && this.selectedPlace) {
        this.$router.replace({ name: 'Home' }).catch(() => {});
      }

      let el = document.getElementById('news-kamakura');
      if (el) {
        this.$smoothScroll({
          scrollTo: el,
          duration: 300,
          updateHistory: false
        });
      }
    }
  }
});
