
import { defineComponent } from 'vue';
import L from 'leaflet';

export default defineComponent({
  name: 'Location',
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    getCurrentPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.$store.dispatch(
              'setMyLocation',
              Object.assign(
                L.latLng(position.coords.latitude, position.coords.longitude),
                { __isDeviceLocation: true }
              )
            );
          },
          () => {},
          {
            timeout: 10000,
            enableHighAccuracy: true
          }
        );
      }
    }
  }
});
