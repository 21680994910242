
import { mapGetters, mapState } from 'vuex'
import Attention from '@/components/Attention.vue'
import Apologize from "@/components/Apologize.vue"
import Header from "@/components/Header.vue"
import Content from '@/components/Home/Content.vue'
import NoticeLine from '@/components/Home/NoticeLine.vue'
import HelpLine from '@/components/Home/HelpLine.vue'
import Description from '@/components/Home/Description.vue'
import News from '@/components/Home/News.vue'
import Footer from "@/components/Footer.vue"
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    Attention,
    Apologize,
    Header,
    Content,
    NoticeLine,
    HelpLine,
    Description,
    News,
    Footer,
  },
  computed: {
    ...mapGetters(['isSP', 'isWV']),
    ...mapState(['selectedPlace']),
  },
})
