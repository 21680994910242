const RegexTable = {
  '0': '[0０]',
  '1': '[1１]',
  '2': '[2２]',
  '3': '[3３]',
  '4': '[4４]',
  '5': '[5５]',
  '6': '[6６]',
  '7': '[7７]',
  '8': '[8８]',
  '9': '[9９]',
  '０': '[0０]',
  '１': '[1１]',
  '２': '[2２]',
  '３': '[3３]',
  '４': '[4４]',
  '５': '[5５]',
  '６': '[6６]',
  '７': '[7７]',
  '８': '[8８]',
  '９': '[9９]',
  'A': '[AaＡａ]',
  'B': '[BbＢｂ]',
  'C': '[CcＣｃ]',
  'D': '[DdＤｄ]',
  'E': '[EeＥｅ]',
  'F': '[FfＦｆ]',
  'G': '[GgＧｇ]',
  'H': '[HhＨｈ]',
  'I': '[IiＩｉ]',
  'J': '[JjＪｊ]',
  'K': '[KkＫｋ]',
  'L': '[LlＬｌ]',
  'M': '[MmＭｍ]',
  'N': '[NnＮｎ]',
  'O': '[OoＯｏ]',
  'P': '[PpＰｐ]',
  'Q': '[QqＱｑ]',
  'R': '[RrＲｒ]',
  'S': '[SsＳｓ]',
  'T': '[TtＴｔ]',
  'U': '[UuＵｕ]',
  'V': '[VvＶｖ]',
  'W': '[WwＷｗ]',
  'X': '[XxＸｘ]',
  'Y': '[YyＹｙ]',
  'Z': '[ZzＺｚ]',
  'a': '[AaＡａ]',
  'b': '[BbＢｂ]',
  'c': '[CcＣｃ]',
  'd': '[DdＤｄ]',
  'e': '[EeＥｅ]',
  'f': '[FfＦｆ]',
  'g': '[GgＧｇ]',
  'h': '[HhＨｈ]',
  'i': '[IiＩｉ]',
  'j': '[JjＪｊ]',
  'k': '[KkＫｋ]',
  'l': '[LlＬｌ]',
  'm': '[MmＭｍ]',
  'n': '[NnＮｎ]',
  'o': '[OoＯｏ]',
  'p': '[PpＰｐ]',
  'q': '[QqＱｑ]',
  'r': '[RrＲｒ]',
  's': '[SsＳｓ]',
  't': '[TtＴｔ]',
  'u': '[UuＵｕ]',
  'v': '[VvＶｖ]',
  'w': '[WwＷｗ]',
  'x': '[XxＸｘ]',
  'y': '[YyＹｙ]',
  'z': '[ZzＺｚ]',
  'Ａ': '[AaＡａ]',
  'Ｂ': '[BbＢｂ]',
  'Ｃ': '[CcＣｃ]',
  'Ｄ': '[DdＤｄ]',
  'Ｅ': '[EeＥｅ]',
  'Ｆ': '[FfＦｆ]',
  'Ｇ': '[GgＧｇ]',
  'Ｈ': '[HhＨｈ]',
  'Ｉ': '[IiＩｉ]',
  'Ｊ': '[JjＪｊ]',
  'Ｋ': '[KkＫｋ]',
  'Ｌ': '[LlＬｌ]',
  'Ｍ': '[MmＭｍ]',
  'Ｎ': '[NnＮｎ]',
  'Ｏ': '[OoＯｏ]',
  'Ｐ': '[PpＰｐ]',
  'Ｑ': '[QqＱｑ]',
  'Ｒ': '[RrＲｒ]',
  'Ｓ': '[SsＳｓ]',
  'Ｔ': '[TtＴｔ]',
  'Ｕ': '[UuＵｕ]',
  'Ｖ': '[VvＶｖ]',
  'Ｗ': '[WwＷｗ]',
  'Ｘ': '[XxＸｘ]',
  'Ｙ': '[YyＹｙ]',
  'Ｚ': '[ZzＺｚ]',
  'ａ': '[AaＡａ]',
  'ｂ': '[BbＢｂ]',
  'ｃ': '[CcＣｃ]',
  'ｄ': '[DdＤｄ]',
  'ｅ': '[EeＥｅ]',
  'ｆ': '[FfＦｆ]',
  'ｇ': '[GgＧｇ]',
  'ｈ': '[HhＨｈ]',
  'ｉ': '[IiＩｉ]',
  'ｊ': '[JjＪｊ]',
  'ｋ': '[KkＫｋ]',
  'ｌ': '[LlＬｌ]',
  'ｍ': '[MmＭｍ]',
  'ｎ': '[NnＮｎ]',
  'ｏ': '[OoＯｏ]',
  'ｐ': '[PpＰｐ]',
  'ｑ': '[QqＱｑ]',
  'ｒ': '[RrＲｒ]',
  'ｓ': '[SsＳｓ]',
  'ｔ': '[TtＴｔ]',
  'ｕ': '[UuＵｕ]',
  'ｖ': '[VvＶｖ]',
  'ｗ': '[WwＷｗ]',
  'ｘ': '[XxＸｘ]',
  'ｙ': '[YyＹｙ]',
  'ｚ': '[ZzＺｚ]',
  '-':  '[－ー-]',
  '－':  '[－ー-]',
  'ー':  '[－ー-]',
  '&':  '[&＆]',
  '＆':  '[&＆]',
  '.':  '\\.',
  '*':  '\\*',
  '+':  '\\+',
  '[':  '\\[',
  ']':  '\\]',
  '{':  '\\{',
  '}':  '\\}',
}

export default RegexTable
