export default {
  genreClass(genre) {
    return {
      'スーパー': 'is-supermarket',
      'ホームセンター': 'is-homecenter',
      'ドラッグストア': 'is-drugstore',
      'ディスカウントショップ': 'is-discountshop',
      '百貨店・モール': 'is-departmall',
    }[genre]
  },
  genreLabel(genre) {
    return {
      'スーパー': 'スーパーマーケット',
      'ディスカウントショップ': 'ディスカウントショップ',
      'ホームセンター': 'ホームセンター',
      'ドラッグストア': 'ドラッグストア',
      '百貨店・モール': '百貨店・モール',
    }[genre]
  },
  genreLabelShort(genre) {
    return {
      'スーパー': 'スーパー',
      'ディスカウントショップ': 'ディスカウント',
      'ホームセンター': 'ホームセンター',
      'ドラッグストア': 'ドラッグ',
      '百貨店・モール': '百貨店・モール',
    }[genre]
  },
  genreAliasToGenre(alias) {
    return {
      'supermarket': 'スーパー',
      'discountshop': 'ディスカウントショップ',
      'homecenter': 'ホームセンター',
      'drugstore': 'ドラッグストア',
      'departmall': '百貨店・モール',
    }[alias]
  },
}
