<template>
  <div class="help-line">
    <div class="container">
      <img src="@/assets/images/noun-send.svg">
      <a href="https://example.com" target="_blank">現在地周辺を表示するには？</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.help-line {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #E4E9EF;

    img {
      width: 18px;
      height: 18px;
    }

    a {
      margin-left: 8px;
      color: #000;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
