import { createStore } from 'vuex'

import L from 'leaflet'
import moment from 'moment'

import genreHelper from '@/lib/GenreHelper.js'

export default createStore({
  state: {
    myLocation: null,
    allPlaces: null,
    lastUpdate: null,
    selectedPlace: null,
    suppressMapMoveOnSelect: false,
    genreFlags: {
      'スーパー': true,
      'ホームセンター': true,
      'ドラッグストア': true,
      'ディスカウントショップ': true,
      '百貨店・モール': true,
    },
    trends: null,
    windowWidth: window.innerWidth,
    utmSource: null,
  },
  mutations: {
    setMyLocation(state, location) {
      state.myLocation = location
    },
    setAllPlaces(state, places) {
      state.allPlaces = places
    },
    setLastUpdate(state, lastUpdate) {
      state.lastUpdate = lastUpdate
    },
    toggleGenre(state, genre) {
      state.genreFlags[genre] = !state.genreFlags[genre]
    },
    setGenre(state, { genre, enable }) {
      state.genreFlags[genre] = enable
    },
    setSelectedPlace(state, place) {
      state.selectedPlace = place
    },
    setTrends(state, trends) {
      state.trends = trends
    },
    windowWidth(state, width) {
      state.windowWidth = width
    },
    setUtmSource(state, utmSource) {
      state.utmSource = utmSource
    },
    setSuppressMapMoveOnSelect(state, suppress) {
      state.suppressMapMoveOnSelect = suppress
    },
  },
  actions: {
    async loadMyLocation({ commit, dispatch, state }) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            commit('setSuppressMapMoveOnSelect', !!state.selectedPlace)
            dispatch(
              'setMyLocation',
              Object.assign(
                L.latLng(position.coords.latitude, position.coords.longitude),
                { __isDeviceLocation: true }
              )
            )
            setTimeout(() => {
              commit('setSuppressMapMoveOnSelect', false)
            }, 1000)
          },
          () => {},
          {
            timeout: 10000,
            enableHighAccuracy: true,
          }
        )
      }

      let response = await fetch('/location')
      let json = await response.json()
      let l = json.location || { latitude: 35.675303, longitude: 139.762179 }

      if (!state.myLocation) {
        dispatch('setMyLocation', L.latLng(l.latitude, l.longitude))
      }
    },
    async loadAllPlaces({ commit, state }) {
      let response = await fetch('/latests_url')
      let json = await response.json()

      response = await fetch(json.url)
      json = await response.json()
      let places = json.latests
        .filter((p) => p.is_nodata != 1)
        .map((p) => {
          let location = L.latLng(p.latitude, p.longitude)
          location.__distance = state.myLocation
            ? state.myLocation.distanceTo(location)
            : null

          return {
            code: p.code,
            genre: p.genre,
            name: p.name,
            address: p.address,
            location: location,
            status: p.status,
            is_nodata: p.is_nodata,
          }
        })
      commit('setLastUpdate', json.lastUpdate)
      commit('setAllPlaces', Object.preventExtensions(places))
    },
    async loadTrends({ commit, state }, place) {
      let response = await fetch('/trends/' + place.code)
      let json = await response.json()
      if (!state.selectedPlace || state.selectedPlace.code != place.code) {
        return
      }
      commit('setTrends', json.trends)
    },
    selectPlace({ commit, dispatch }, place) {
      commit('setSelectedPlace', place)
      commit('setTrends', null)
      dispatch('loadTrends', place)
    },
    deselectPlace({ commit }) {
      commit('setSelectedPlace', null)
      commit('setTrends', null)
    },
    toggleGenre({ commit, state, dispatch }, genre) {
      commit('toggleGenre', genre)
      if (state.selectedPlace && !state.genreFlags[state.selectedPlace.genre]) {
        dispatch('deselectPlace')
      }
    },
    initGenre({ commit, state }, genreAlias) {
      let genre = genreHelper.genreAliasToGenre(genreAlias)
      if (genre) {
        for (let g in state.genreFlags) {
          commit('setGenre', { genre: g, enable: false })
        }
        commit('setGenre', { genre: genre, enable: true })
      }
    },
    setMyLocation({ commit, state }, location) {
      commit('setMyLocation', location)

      if (state.allPlaces) {
        for (let p of state.allPlaces) {
          // メモリあまり使いたくないので中のobjectを直に書き換え
          p.location.__distance = state.myLocation.distanceTo(p.location)
        }
      }
    },
  },
  modules: {},
  getters: {
    places(state) {
      return state.allPlaces
        ? state.allPlaces.filter((p) => state.genreFlags[p.genre])
        : null
    },
    isPC(state) {
      return 768 <= state.windowWidth
    },
    isSP(state) {
      return 768 > state.windowWidth
    },
    lastUpdateDateTime(state) {
      return state.lastUpdate
        ? moment(state.lastUpdate).utcOffset('+0900').format('YYYY/MM/DD HH:mm')
        : null
    },
    isWV(state) {
      return state.utmSource == 'smartnews'
    },
  },
})
