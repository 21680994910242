
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Attention',
  data: function () {
    return {
      attentionClosed: localStorage.getItem('attentionClosed')
    };
  },
  computed: {
    needAttention(): boolean {
      if (this.attentionClosed) {
        return (
          parseInt(this.attentionClosed) + 1000 * 60 * 60 * 24 * 7 <=
          new Date().getTime()
        );
      } else {
        return true;
      }
    }
  },
  methods: {
    close() {
      this.attentionClosed = new Date().getTime().toFixed();
      localStorage['attentionClosed'] = this.attentionClosed;
    }
  }
});
