import "@/vendor/cookie-consent-lib.css";
import {Store, Banner} from "@/vendor/cookie-consent-lib.js";

let store = new Store({categories: ["confirmation"], acceptedState: "accepted", schemaVersion: 2});

store.addListener({
  onUpdate: function (store) {
    if (!store.needNotice()) {
      banner.hide();
    }
  }
});

let banner = new Banner({
  labels: {
    accept: "OK",
  },
  description: 'このサイトでは、利便性向上やパーソナライズされた情報・広告配信のため、クッキーを使用しています。' +
               'これ以降のサイトご利用は、クッキーの設定や使用に同意をしたものとします。詳細やオプトアウトについては、<a href="https://www.unerry.co.jp/privacy/" target="_blank" rel="noopener">プライバシーポリシー</a>をご覧ください。',
});

banner.addListener({
  onAccept: function() {
    store.acceptAll()
  },
});

document.addEventListener("DOMContentLoaded", function() {
  if (store.needNotice()) {
    banner.show();
  }
});
