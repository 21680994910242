
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex'
import '@/assets/styles/social-button.override.scss'

export default defineComponent({
  name: 'Description',
  computed: {
    ...mapState(['windowWidth']),
    ...mapGetters(['lastUpdateDateTime', 'isWV']),
  },
  mounted () {
    try {
      window.LineIt && typeof window.LineIt.loadButton === 'function' && window.LineIt.loadButton()
      window.twttr && window.twttr.widgets && typeof window.twttr.widgets.load === 'function' && window.twttr.widgets.load()
      window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function' && window.FB.XFBML.parse()
    } catch (e) {
      console.log(e)
    }
  }
})
