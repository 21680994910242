import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-596addf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "apologize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.closed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(" 現在、一時的に推定混雑状況の更新が遅れております。["),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closed = true), ["prevent"]))
        }, "閉じる"),
        _createTextVNode("] ")
      ]))
    : _createCommentVNode("", true)
}