
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import Map from '@/components/Home/Map.vue'
import SearchPC from '@/components/Home/Search.pc.vue'
import SearchSP from '@/components/Home/Search.sp.vue'
import Genre from '@/components/Home/Genre.vue'
import Location from '@/components/Home/Location.vue'
import DetailPC from '@/components/Home/Detail.pc.vue'
import DetailSP from '@/components/Home/Detail.sp.vue'

export default defineComponent({
  name: 'Content',
  components: {
    Map,
    SearchPC,
    SearchSP,
    Genre,
    Location,
    DetailPC,
    DetailSP,
  },
  computed: {
    ...mapGetters(['places', 'isPC', 'isSP']),
    ...mapState(['myLocation', 'selectedPlace']),
  },
})
