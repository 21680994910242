
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Apologize',
  data: function () {
    return {
      closed: true // 障害発生時 falseにする
    };
  }
});
