import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'お買物混雑マップ　Powered by Beacon Bank',
      description:
        '日用品お買物時に、スーパーやドラッグストア等のお店近辺の曜日・時間帯別混雑傾向が調べられます。「密」を避ける行動のための参考情報としてご利用ください。',
      canonical: 'https://covid19.unerry.jp/',
    },
    children: [
      {
        name: 'Detail',
        path: 'detail/:code',
        component: Home,
        meta: {
          title: 'お買物混雑マップ　Powered by Beacon Bank',
        },
      },
    ],
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms.vue'),
    meta: {
      title: '利用規約 | お買物混雑マップ　Powered by Beacon Bank',
      description: 'お買物混雑マップの利用規約ページです',
      canonical: 'https://covid19.unerry.jp/terms',
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.name === from.name) {
      return
    }
    if (to.name === 'Detail' && from.name === 'Home') {
      return
    }
    if (to.name === 'Home' && from.name === 'Detail') {
      return
    }

    return { left: 0, top: 0 }
  },
})

router.isReady().then(() => {
  store.dispatch('initGenre', router.currentRoute.value.query.category)
})

// GAのパラメータは持ち回す
const RetainQueryRegex = [new RegExp('^utm_.*$')]

router.beforeEach((to, from, next) => {
  let retainParams = {}

  for (let name in from.query) {
    for (let re of RetainQueryRegex) {
      if (!to.query[name] && name.match(re)) {
        retainParams[name] = from.query[name]
        break
      }
    }
  }

  if (0 < Object.keys(retainParams).length) {
    next(
      Object.assign({}, to, {
        query: Object.assign({}, to.query, retainParams),
      })
    )
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Detail') {
    // allPlacesが読み込まれていなければwatchで処理
    if (store.state.allPlaces) {
      let place = store.getters.places.find((p) => p.code === to.params.code)
      if (place) {
        store.dispatch('selectPlace', place)
      } else {
        next('/')
        return
      }
    }
  } else {
    store.dispatch('deselectPlace')
  }
  next()
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if (store.state.selectedPlace) {
      document.title = store.state.selectedPlace.name + ' | ' + to.meta.title
    } else {
      document.title = to.meta.title
    }
  }
  if (to.meta.description) {
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', to.meta.description)
  }
  if (to.meta.canonical) {
    document
      .querySelector("link[rel='canonical']")
      .setAttribute('href', to.meta.canonical)
  }
  next()
})

router.beforeEach((to, from, next) => {
  store.commit('setUtmSource', to.query.utm_source)
  next()
})

router.afterEach((to) => {
  if (to.name === 'Detail') {
    // selectedPlaceが読み込まれていなければwatchで処理
    if (store.state.selectedPlace) {
      window.PageView({
        page_title: store.state.selectedPlace.name,
        page_path: to.fullPath,
        page_location: location.origin + to.fullPath,
      })
    }
  } else {
    window.PageView({
      page_title: document.title,
      page_path: to.fullPath,
      page_location: location.origin + to.fullPath,
    })
  }
})

// /detail/xxxxx のURLに直アクセスした場合の考慮
let unwatch = store.watch(
  (state) => state.allPlaces,
  (allPlaces) => {
    if (allPlaces) {
      let current = router.currentRoute

      if (current.value.name === 'Detail') {
        let place = store.getters.places.find(
          (p) => p.code === current.value.params.code
        )
        if (place) {
          store.dispatch('selectPlace', place)
          document.title = place.name + ' | ' + current.value.meta.title
          window.PageView({
            page_title: place.name,
            page_path: current.value.fullPath,
            page_location: location.origin + current.value.fullPath,
          })
        } else {
          router.replace({ name: 'Home' }).catch(() => {})
        }
      }

      unwatch()
    }
  }, 
  { deep: true }
)

export default router
