import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38877ebe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "notice-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.closed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, [
          _createTextVNode("混雑状況表示が7日から8日間に増えました。 ["),
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closed = true), ["prevent"]))
          }, "閉じる"),
          _createTextVNode("]")
        ])
      ]))
    : _createCommentVNode("", true)
}