
import { defineComponent, toRaw } from 'vue'
import { mapGetters, mapState } from 'vuex'

import 'leaflet/dist/leaflet.css'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@/assets/styles/leaflet.override.scss'

import L from 'leaflet'
import '@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl.js'

import maplibregl from 'maplibre-gl/dist/maplibre-gl.js'
(maplibregl.config as any).API_URL = null // https://events.maplibre.com/events/v2?access_token=not-needed 401のエラーになるのを回避

import urlIcon0 from '@/assets/images/ico-nodata.png'
import urlIcon1 from '@/assets/images/ico38-light.png'
import urlIcon2 from '@/assets/images/ico38-normal.png'
import urlIcon3 from '@/assets/images/ico38-heavy.png'
import urlIconSelected from '@/assets/images/pin.png'

import urlIconRetina0 from '@/assets/images/ico-nodata@2x.png'
import urlIconRetina1 from '@/assets/images/ico38-light@2x.png'
import urlIconRetina2 from '@/assets/images/ico38-normal@2x.png'
import urlIconRetina3 from '@/assets/images/ico38-heavy@2x.png'
import urlIconRetinaSelected from '@/assets/images/pin@2x.png'

import urlMyLocation from '@/assets/images/my-location.svg'

const selectedIcon = L.icon({
    iconUrl: urlIconSelected,
    iconRetinaUrl: urlIconRetinaSelected,
    iconSize: [40, 48],
    iconAnchor: [20.5, 58],
})

const myLocationIcon = L.icon({
    iconUrl: urlMyLocation,
    iconSize: [23, 23],
})

const icons = {
  '不明': L.icon({iconUrl: urlIcon0, iconRetinaUrl: urlIconRetina0, iconSize: [30, 30], iconAnchor: [14.5, 18]}),
  '閑散': L.icon({iconUrl: urlIcon1, iconRetinaUrl: urlIconRetina1, iconSize: [38, 38]}),
  '通常': L.icon({iconUrl: urlIcon2, iconRetinaUrl: urlIconRetina2, iconSize: [38, 38]}),
  '混雑': L.icon({iconUrl: urlIcon3, iconRetinaUrl: urlIconRetina3, iconSize: [38, 38]}),
}

export default defineComponent ({
  name: 'Map',
  computed: {
    ...mapGetters(['places', 'isSP', 'isWV']),
    ...mapState(['myLocation', 'selectedPlace', 'suppressMapMoveOnSelect']),
    placeCodeSet (): Set<unknown> {
      return  new Set(Array.isArray(this.places) ? this.places.map((p: { code: any }) => p.code) : [])
    }
  },
  data() {
    return {
      markers: Object as any,
      map: Object as unknown as L.Map,
      selectedMarker: Object as unknown as L.Marker,
      myLocationMarker: Object as unknown as L.Marker
    }
  },
  watch: {
    places:{
      handler () {
        this.refreshMarker()
      },
      deep: true
    },
    selectedPlace: {
      handler () {
        this.refreshSelectedMarker()
      },
      deep: true
    },
    myLocation: {
      handler () {
        if (this.map && !this.suppressMapMoveOnSelect) {
          this.map.setView(this.myLocation, 15, {animate: true, duration: 0.2})
        }

        this.refreshMyLocation()
      }, 
      deep: true,
    }
  },
  mounted () {
    const map = L.map('map', {scrollWheelZoom: false}).setView(this.myLocation, 15)
    map.zoomControl.setPosition('topright')

    L.maplibreGL({
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a> | <a href="https://www.maxmind.com" target="_blank">GeoLite2 by MaxMind</a>',
      accessToken: 'not-needed',
      style: `https://api.maptiler.com/maps/streets/style.json?key=${process.env.VUE_APP_MAPTILER_API_KEY}`
    } as any).addTo(map);

    map.on('moveend', this.refreshMarker.bind(this))
    map.on('zoomend', this.refreshMarker.bind(this))
    map.on('click', () => this.$router.replace({name: 'Home'}).catch(() => {}))

    this.selectedMarker = L.marker([0, 0], {icon: selectedIcon, opacity: 0, zIndexOffset: 10000})
    toRaw(this.selectedMarker).addTo(map)

    this.myLocationMarker = L.marker([0, 0], {icon: myLocationIcon, opacity: 0, zIndexOffset: 9000, interactive: false})
    toRaw(this.myLocationMarker).addTo(map)

    this.map = map;

    this.refreshMarker()
    this.refreshSelectedMarker()
    this.refreshMyLocation()
  },
  methods: {
    refreshMarker() {
      if (!this.map || !this.places) {
        return
      }

      let bounds = this.map.getBounds().pad(0.2)

      for (let code in this.markers) {
        let marker = toRaw(this.markers[code])
        if (!this.placeCodeSet.has(code) || !bounds.contains(marker.getLatLng())) {
          marker.removeFrom(toRaw(this.map))
          delete this.markers[code]
        }
      }

      let places = this.places.filter((place: { location: any }) => bounds.contains(place.location))
      places = places.slice(0, Math.max(0, 500 - Object.keys(this.markers).length))

      for (let place of places) {
        if (!this.markers[place.code]) {
          let marker = L.marker(place.location, {icon: icons[place.status as keyof typeof icons]})
          marker.on('click', () => {
            this.$router.replace({name: 'Detail', params: {code: place.code}}).catch(() => {})
          })
          marker.bindTooltip(place.name)
          this.markers[place.code] = marker
          marker.addTo(toRaw(this.map) as any)
        }
      }
    },
    refreshSelectedMarker() {
      if (this.map) {
        if (this.selectedPlace) {
          toRaw(this.selectedMarker).setLatLng(this.selectedPlace.location)
          toRaw(this.selectedMarker).setOpacity(1)

          let targetZoom = Math.max(toRaw(this.map).getZoom(), 17);

          let targetPoint = this.isSP ? toRaw(this.map).project(this.selectedPlace.location, targetZoom).add([0, (this.$refs['map'] as any).clientHeight / 8])
                          : toRaw(this.map).project(this.selectedPlace.location, targetZoom).add([window.innerWidth / 6, 0])
          let targetLatLng = toRaw(this.map).unproject(targetPoint, targetZoom)
          toRaw(this.map).setView(targetLatLng, targetZoom, {animate: true, duration: 0.2})
        } else {
          toRaw(this.selectedMarker).setOpacity(0)
        }
      }
    },
    refreshMyLocation() {
      if (this.isWV && this.map) {
        toRaw(this.myLocationMarker).setLatLng(this.myLocation)
        toRaw(this.myLocationMarker).setOpacity(this.myLocation.__isDeviceLocation ? 1 : 0)
      }
    },
  }
})
