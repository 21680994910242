
import { mapState, mapActions } from 'vuex';
import genreHelper from '@/lib/GenreHelper.js';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Genre',
  computed: mapState(['genreFlags']),
  data() {
    return {
      genres: [
        'スーパー',
        'ドラッグストア',
        '百貨店・モール',
        'ディスカウントショップ',
        'ホームセンター'
      ]
    };
  },
  methods: {
    ...mapActions(['toggleGenre']),
    isActive(genre:string){
      return this.genreFlags[genre];
    },
    genreClass: genreHelper.genreClass,
    genreLabelShort: genreHelper.genreLabelShort
  }
});
