import { mapGetters } from 'vuex'
import RegexTable from '@/lib/RegexTable.js'
import genreHelper from '@/lib/GenreHelper.js'
import statusHelper from '@/lib/StatusHelper.js'

import debounce from 'lodash.debounce'

export default {
  computed: mapGetters(['places', 'isPC']),
  data () {
    return {
      word: '',
      filtered: [],
      focused: null,
      isNotFound: false,
    }
  },
  watch: {
    word: {
      handler (val) {
        this.filterPlace(val)
      },
      deep: true,
    },
    places :{
      handler () {
        if (0 < this.filtered.length) {
          this.focused = null
          this.filterPlace(this.word)
        }
      },
      deep: true,
    },
  },
  methods: {
    filterPlace: debounce(function (val) {
      this.isNotFound = false

      let word = val.trim()
      if (word.length == 0) {
        this.filtered = []
        return
      }

      let words = word.split(/\s+/).filter(s => s.length > 0)
      words = words.map(w => {
        return w.replace(/[a-zA-Zａ-ｚＡ-Ｚ０-９0-9&＆.*+{}[\]-]/g, c => {
          return RegexTable[c]
        })
      })

      let regexps = words.map(w => new RegExp(w, 'i'))

      this.filtered = this.places.filter((place) => {
        return regexps.every(regexp => {
          return place.name.match(regexp) || place.address.match(regexp)
        })
      }).sort((a, b) => {
        return a.location.__distance - b.location.__distance
      })

      this.isNotFound = 0 == this.filtered.length
    }, 200),
    selectPlace(place) {
      this.filtered = []
      this.focused = null;
      this.$router.replace({name: 'Detail', params: {code: place.code}, query: {q: this.word}}).catch(() => {})
    },
    genreClass: genreHelper.genreClass,
    statusClass: statusHelper.statusClass,
    statusSearchLabel: statusHelper.statusSearchLabel,
    setFocus(place) {
      this.focused = place
    },
    unsetFocus(place) {
      if (this.focused === place) {
        this.focused = null
      }
    },
    isFocused(place) {
      return this.focused === place
    },
    onKeyUp() {
      if (0 === this.filtered.length) {
        if (0 < this.word.length) {
          this.filterPlace(this.word);
          if (0 === this.filtered.length) {
            return
          }
        }
      }
      if (!this.focused) {
        this.focused = this.filtered[this.filtered.length - 1]
      } else {
        this.focused = this.filtered[(this.filtered.indexOf(this.focused) + this.filtered.length - 1) % this.filtered.length]
      }
    },
    onKeyDown() {
      if (0 === this.filtered.length) {
        if (0 < this.word.length) {
          this.filterPlace(this.word);
          if (0 === this.filtered.length) {
            return
          }
        }
      }
      if (!this.focused) {
        this.focused = this.filtered[0]
      } else {
        this.focused = this.filtered[(this.filtered.indexOf(this.focused) + 1) % this.filtered.length]
      }
    },
    onEnter() {
      if (this.focused) {
        this.selectPlace(this.focused)
      } else {
        if (0 < this.word.length) {
          this.filterPlace(this.word);
        }
      }
    },
    onFocus() {
      if (0 < this.word.length) {
        this.filterPlace(this.word);
      }
    },
    onChange() {
      this.focused = null
    },
  }
}
